import { getReq } from '../service/API'
import useSWR from 'swr'
import { BASE_URL } from '../constants'
//import { errorStore } from '../store/ErrorStore'

export const useEventsGroupByProcess = (studyId: string) => {
  //const setError = errorStore((state) => state.setError)
  const p = BASE_URL + '/api/eventsGroupByProcess?studyId=' + studyId
  const { data, error } = useSWR(p, getReq)

  /*if (error) {
    setError(error.error)
  }*/

  return {
    data: !data ? data : data.data,
    isLoading: !error && !data,
    isError: error,
  }
}
