/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 11/10/2021.
 */
import React, { useState } from 'react'
import { QuestionType } from '../../Types'

type Props = {
  question: QuestionType
  selectItem: (posibility, questionId) => void
  hasError: boolean
}

export const TextFree = ({ question, selectItem, hasError }: Props) => {
  const txt = question.question.da

  const [inputTxt, setInputTxt] = useState('')
  const onchange = (e) => {
    setInputTxt(e.target.value)
  }

  const onBlurChange = (e: any) => {
    selectItem(inputTxt, question)
  }

  return (
    <div
      className="col-12 mt-3 py-4 mb-2 bg-white shadow-sm d-flex flex-column align-items-center "
      style={{ border: hasError ? '2px solid red' : 'none' }}
    >
      <p>{txt}</p>

      <div className="mt-3 w-50">
        <input
          type="text"
          placeholder="Write your answer here"
          onBlur={onBlurChange}
          onChange={onchange}
          value={inputTxt}
          className="p-2 w-100"
          style={{ fontSize: '.8rem' }}
        />
      </div>
    </div>
  )
}
