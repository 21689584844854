export type Pages = {
  title: string
  to: string
  visible: boolean
}

export const FRONT_PAGE: Pages = { title: 'Overview', to: `/`, visible: true }
export const STUDY_PAGE: Pages = { title: 'Subject', to: `/:studyId`, visible: false }
export const SUBJECT_PAGE: Pages = {
  title: 'Subject',
  to: STUDY_PAGE.to + '/:enrollmentId/:subDataEventId',
  visible: false,
}
