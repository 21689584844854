import { AnswerObj, QuestionType } from '../Types'
import { INFORMATION } from '../constants'

export const validateAnswers = (answerArr: AnswerObj[], questionArr: QuestionType[]) => {
  console.log(' validateAnswers > what = ')

  const visibleQuestions = questionArr
    //
    // Remove all type INFORMATION since they can ont be answred
    //
    .filter((item) => {
      return item.type !== INFORMATION
    })

    .filter((item) => {
      const showRules = item.showRules

      if (showRules) {
        const shouldShow = showRules[0].possibilities.filter((pos: any) => {
          const anyMatchs = answerArr.filter((answerObj: AnswerObj) => {
            if (answerObj.possibilities && answerObj.question === showRules[0].question) {
              return answerObj && answerObj.possibilities ? answerObj.possibilities.indexOf(pos) > -1 : false
            } else {
              return answerObj && answerObj.answerStrings ? answerObj.answerStrings.indexOf(pos) > -1 : false
            }
          })
          return anyMatchs.length !== 0
        })

        return shouldShow.length === 0 ? false : true
      } else {
        return true
      }
    })

  const requiredNotFilled = visibleQuestions
    .filter((item) => {
      const isAnswred = answerArr.filter((answer) => {
        return answer.question === item._id
      })

      console.log(' validateAnswers > item = ', item.type)
      const isMissing = item.required === true && isAnswred.length === 0
      return isMissing
    })
    .map((notFilled) => {
      console.log(' validateAnswers > no = ', notFilled)
      return notFilled._id
    })

  return requiredNotFilled
}
