import create, { State } from 'zustand'
import { BASE_URL } from '../constants'
import { post } from '../service/API'

export interface DataStoreType extends State {
  selectedSubject: any
  setSelectedSubject: (subject: any) => void
}

export const dataStore = create<DataStoreType>((set: any, get: any) => {
  return {
    selectedSubject: null,

    setSelectedSubject: (subject) => {
      set({ selectedSubject: subject })
    },

    loadEvaluationForm: (studyId, forms) => {
      const p = BASE_URL + '/api/evaluationForm'
      post(p, { studyId: studyId, forms: forms })
        .then((result) => {
          console.log('result = ', result)
        })
        .catch((error) => {
          console.log('error = ', error)
        })
    },
  }
})
