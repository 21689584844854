/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 11/10/2021.
 */
import React, { useEffect } from 'react'
import { AnswerObj, QuestionType } from '../../Types'

type Props = {
  question: QuestionType
  selectItem: (posibility, questionId) => void
  hasError: boolean
  answerToQuestionArr: AnswerObj[]
  prefilledValue: string
}

export const TextPrefilled = ({ question, selectItem, hasError, answerToQuestionArr, prefilledValue = '' }: Props) => {
  const txt = question.question.da
  const answerToQuestion = answerToQuestionArr.length > 0 ? answerToQuestionArr[0] : null
  const answerValue = answerToQuestion && answerToQuestion.answerStrings ? answerToQuestion.answerStrings[0] : ''

  useEffect(() => {
    selectItem(prefilledValue, question)
    // eslint-disable-next-line
  }, [])

  return (
    <div
      className="col-12 mt-3 py-4 mb-2 bg-white shadow-sm d-flex flex-column align-items-center "
      style={{ border: hasError ? '2px solid red' : 'none' }}
    >
      <p>{txt}</p>

      <div className="mt-3 w-50 text-center">
        <h6 className="font-weight-bold">{answerValue}</h6>
      </div>
    </div>
  )
}
