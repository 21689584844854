import { getReq } from '../service/API'
import useSWR from 'swr'
import { BASE_URL } from '../constants'
//import { errorStore } from '../store/ErrorStore'

export const useGetAnswersByDataEvent = (
  studyId: string,
  enrollmentId: string | null,
  dataEventId: string | null,
  dataEventRegistrationId: string | null
) => {
  //
  // call should only be made if enrollmentId && dataEventId && dataEventRegistrationId has been defined
  // to conditionally call make the call we use the hasParams to construct the cacheKey
  //

  const hasParams = enrollmentId && dataEventId && dataEventRegistrationId ? true : false
  const p =
    BASE_URL +
    `/api/getAnswersByDataEvent?studyId=${studyId}&enrollmentId=${enrollmentId}&dataEventId=${dataEventId}&dataEventRegistrationId=${dataEventRegistrationId}`

  const cacheKey = hasParams ? [p, studyId, enrollmentId, dataEventId] : null

  const { data, error } = useSWR(cacheKey, getReq)

  /*if (error) {
		setError(error.error)
	}*/

  return {
    data: !data ? data : data.data,
    isLoading: !error && !data,
    isError: error,
  }
}
