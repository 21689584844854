/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 30/09/2021.
 */
import React from 'react'

import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { Header } from './components/header/Header'

import { FRONT_PAGE, STUDY_PAGE, SUBJECT_PAGE } from './pages'
import { EnrollmentListPage } from './pages/enrollment/EnrollmentListPage'
import { StudyPage } from './pages/study/StudyPage'
import { SubjectPage } from './pages/subject/SubjectPage'

export const Main = () => {
  return (
    <Router>
      <Header />

      <Switch>
        <Route exact path={FRONT_PAGE.to}>
          <StudyPage></StudyPage>
        </Route>

        <Route exact path={STUDY_PAGE.to}>
          <EnrollmentListPage></EnrollmentListPage>
        </Route>

        <Route exact path={SUBJECT_PAGE.to}>
          <SubjectPage></SubjectPage>
        </Route>

        <Redirect to={FRONT_PAGE.to} />
      </Switch>
    </Router>
  )
}
