/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 07/10/2021.
 */
import React from 'react'
import { Link } from 'react-router-dom'
import { studyIds } from '../../studyIds'

export const StudyPage = () => {
  return (
    <div className="container mt-4">
      <div className="row" style={{}}>
        {studyIds.map((item, index) => {
          const to = '/' + item.id
          return (
            <Link to={to} key={index} className="col-12 item-shadow mb-3 py-4 text-decoration-none">
              <p>{item.id}</p>
              <h4>{item.title}</h4>
            </Link>
          )
        })}
      </div>
    </div>
  )
}
