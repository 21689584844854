import { AnswerObj, QuestionType } from '../Types'

export const collectAnswers = (answerValue: string, question: QuestionType, answerArr: AnswerObj[]) => {
  // has it been answered before
  const hasBeenAnsweredArr = answerArr.filter((answer) => {
    return answer.question === question._id
  })

  //
  // TEXT_FREE question id not already answered
  //
  if (hasBeenAnsweredArr.length === 0 && question.type === 'TEXT_FREE') {
    const obj: AnswerObj = {
      question: question._id,
      answerStrings: [answerValue],
    }

    //
    // dont inject empty value
    //
    if (answerValue === '') {
      return answerArr.concat([])
    } else {
      return answerArr.concat([obj])
    }
  }

  //
  // the text free question has been answered - but now the value needs to be updated
  // We should make sure that the input is not changes to a blank
  //
  if (hasBeenAnsweredArr.length > 0 && question.type === 'TEXT_FREE') {
    const exitingAnswer: AnswerObj = { ...hasBeenAnsweredArr[0] }

    if (exitingAnswer && exitingAnswer.answerStrings) {
      if (answerValue === '') {
        return answerArr.filter((answer) => {
          return answer.question !== exitingAnswer.question
        })
      } else {
        exitingAnswer.answerStrings[0] = answerValue
        return replaceAnswerWithUpdatedAnswer(exitingAnswer, answerArr)
      }
    }
  }

  //
  // create MULTIPLE_RESPONSE and MULTIPLE_CHOICE - which uses the key:possibilities
  //
  if (
    hasBeenAnsweredArr.length === 0 &&
    (question.type === 'MULTIPLE_RESPONSE' || question.type === 'MULTIPLE_CHOICE')
  ) {
    //
    // When multiple_response type - wee need to add the ID
    //
    const obj: AnswerObj = {
      question: question._id,
      possibilities: [answerValue],
    }
    return answerArr.concat([obj])
  }

  //
  // the question has been answered before - MULTIPLE_RESPONSE allow multiple posibilities
  //
  if (hasBeenAnsweredArr.length > 0 && question.type === 'MULTIPLE_RESPONSE') {
    const exitingAnswer: AnswerObj = { ...hasBeenAnsweredArr[0] }
    return validatePosibilityInSavedAnswer(exitingAnswer, answerValue, answerArr)
  }

  if (hasBeenAnsweredArr.length > 0 && question.type === 'MULTIPLE_CHOICE') {
    const exitingAnswer: AnswerObj = { ...hasBeenAnsweredArr[0] }
    if (exitingAnswer && exitingAnswer.possibilities) {
      exitingAnswer.possibilities[0] = answerValue
    }

    return replaceAnswerWithUpdatedAnswer(exitingAnswer, answerArr)
  }

  return []
}

// replace the exiting in the array
const replaceAnswerWithUpdatedAnswer = (newAnswer, answerArr) => {
  const updated = answerArr.map((answer) => {
    return answer.question === newAnswer.question ? newAnswer : answer
  })
  return updated
}

const addMultipleResponseItem = (exitingAnswer, answerValue: string, answerArr) => {
  //
  // the answerValue has not been answerd - add it
  //
  const updatedArr = exitingAnswer.possibilities.concat([answerValue])
  exitingAnswer.possibilities = updatedArr

  return answerArr.map((answer) => {
    return answer.question === exitingAnswer.question ? exitingAnswer : answer
  })
}

//
// since this is a MULTIPLE_RESPONSE we know the answer value is saved in posibility array
//
const validatePosibilityInSavedAnswer = (exitingAnswer, answerValue: string, answerArr) => {
  if (exitingAnswer && exitingAnswer.possibilities) {
    //
    // validate if the id is already in the possibilities
    //
    const posExist = exitingAnswer.possibilities.indexOf(answerValue)

    //
    // If it do not exist - then add it
    //
    if (posExist === -1) {
      return addMultipleResponseItem(exitingAnswer, answerValue, answerArr)
    } else {
      //
      // If it do exist - then remove it
      //
      exitingAnswer.possibilities =
        exitingAnswer &&
        exitingAnswer.possibilities?.filter((p) => {
          return p !== answerValue
        })

      //
      // If the possibilities is then empty - we should remove the whole answer
      //
      if (exitingAnswer.possibilities?.length === 0) {
        return answerArr.filter((item) => {
          return item.question === exitingAnswer.question ? false : true
        })
      } else {
        //
        // If there are possibilities left in the array - replace with the updated exitingAnswer
        //
        const newArr = answerArr.map((item) => {
          return item.question === exitingAnswer.question ? exitingAnswer : item
        })
        return newArr
      }
    }
  }
}
