/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 01/10/2021.
 */
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { dataStore } from '../../store/dataStore'
import { useEventsGroupByProcess } from '../../hooks/useEventsGroupByProcess'
import { COLOR_PLACEHOLDER, STATUS_PENDING } from '../../constants'
import { EventsGroupByProcess } from '../../Types'
import { ErrorView } from '../../components/error/ErrorView'
import { SpinnerFullHeight } from '../../components/spinner/SpinnerFullHeight'

export const EnrollmentListPage = () => {
  const { studyId } = useParams() as { studyId: string }
  const { data, isError }: { data: EventsGroupByProcess[]; isError: any } = useEventsGroupByProcess(studyId)
  const setSelectedSubject = dataStore((state) => state.setSelectedSubject)
  console.log(' EnrollmentListPage > data = ', data)

  if (isError) {
    return <ErrorView isError={isError}></ErrorView>
  }

  const selectedClick = (item) => {
    setSelectedSubject(item)
  }

  //
  // The subjects should be sorted to show the subject with cloest expire date
  // to avoid looping multiple times we mutate the data and append an expire propety to the Object
  //

  const sortedByExpireDate =
    data &&
    data
      .map((enrollment) => {
        const expire = enrollment.events.reduce((acc, item) => {
          const ex = item.evaluationStatus === 'PENDING' ? new Date(item.subDataEvent.expireDate).getTime() : 0

          if (ex > acc) {
            return ex
          }
          return acc
        }, 0)

        //
        enrollment.expire = expire
        return enrollment
      })
      .sort((a, b) => {
        const aExpire = a.expire ? a.expire : 0
        const bExpire = b.expire ? b.expire : 0

        if (aExpire > bExpire) {
          return -1
        } else if (aExpire === 0 || bExpire === 0) {
          return 1
        }

        return -1
      })

  return (
    <div className="container">
      <div className="row" style={{}}>
        <div className="col-12 mt-4">
          <h2 className="mb-0 font-weight-bold">Subject overview</h2>
          <p>Please select a subject to do a evaluation</p>
        </div>
      </div>
      <div className="row mt-4" style={{}}>
        {sortedByExpireDate ? (
          sortedByExpireDate.map((item, index) => {
            return <SubjectItem key={index} studyId={studyId} item={item} selectedClick={selectedClick} />
          })
        ) : (
          <SpinnerFullHeight>
            <h4 className="mt-3">Loading subjects</h4>
          </SpinnerFullHeight>
        )}
      </div>
    </div>
  )
}

type SubjectItemProps = {
  selectedClick: (item: any) => void
  item: EventsGroupByProcess
  studyId: string
}

const dateFormat = (dateString: string) => {
  const s = dateString.toString().split('T')
  const t = s[1].toString().split('.')[0]
  return s[0] + ' ' + t
}

const SubjectItem = ({ studyId, item, selectedClick }: SubjectItemProps) => {
  const clickker = () => {
    selectedClick(item)
  }

  const isPending = item.events.filter((event) => {
    return event.evaluationStatus === STATUS_PENDING
  })

  return (
    <>
      <div className="col-12 mb-2 pt-4 pb-1  item-shadow">
        <div className="row mb-2" style={{}}>
          <div className="col-4">
            <p className="p-small text-muted">SubjectId: </p>
            <h5 className="">{item.enrollment.subject.subjectId}</h5>
          </div>
        </div>

        <div className="row px-2 mt-3">
          {isPending
            .sort((a, b) => {
              return new Date(a.subDataEvent.expireDate).getTime() > new Date(b.subDataEvent.expireDate).getTime()
                ? 1
                : -1
            })
            .map((pendingEvent, subIndex) => {
              //console.log(' EnrollmentListPage > pendingEvent = ', pendingEvent)
              return (
                <div key={subIndex} className="col-12 mb-2">
                  <div className="row rounded" style={{ background: COLOR_PLACEHOLDER }}>
                    <div className="col-4 mb-2 py-4">
                      <p className="p-small ">Evaluation</p>
                      <p className="text-black">{pendingEvent.dermProcessEvent.dataEventName}</p>
                    </div>
                    <div className="col-3 mb-2 py-4">
                      <p className="p-small ">Activated on</p>
                      <p className="text-black">{dateFormat(pendingEvent.subDataEvent.activationDate)}</p>
                    </div>
                    <div className="col-3 mb-2 py-4">
                      <p className="p-small ">Expire date</p>
                      <p className="text-black">{dateFormat(pendingEvent.subDataEvent.expireDate)}</p>
                    </div>
                    <div className="col-2 mb-2 py-4 d-flex justify-content-end">
                      <div onClick={clickker}>
                        <Link
                          to={'/' + studyId + '/' + item.enrollment._id + '/' + pendingEvent.subDataEvent.dataEvent}
                          onClick={clickker}
                        >
                          <div className="btn btn-primary px-4 py-2 ">Evaluate</div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </>
  )
}
