/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 11/10/2021.
 */
import React from 'react'

type Props = {
  data: any
}

export const Information = ({ data }: Props) => {
  const html = data.question.da
  return (
    <div className="col-12 py-4 mb-2 d-flex justify-content-center">
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  )
}
