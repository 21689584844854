/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 01/10/2021.
 */
import React from 'react'
import { Link } from 'react-router-dom'
import { authStore } from '../../store/auth/authStore'

export const Header = () => {
  const logout = authStore((state) => state.logout)

  const user = authStore((state) => state.user)

  const loguserOut = () => {
    logout()
  }

  return (
    <div className="container-fluid bg-white" style={{ boxShadow: '0px -5px 10px 7px rgba(0, 0, 0, 0.05)' }}>
      <div className="row py-4" style={{}}>
        <div className="col-12 col-md-6 pb-0 d-flex justify-content-center justify-content-md-start">
          <Link to="/" className="mb-0 text-decoration-none">
            <h4 className="mb-0 font-weight-bold">COAK Dermview</h4>
          </Link>
        </div>
        <div className="col-12 col-md-6 d-flex justify-content-end align-items-center">
          <div className="d-block d-md-flex justify-content-end w-100">
            <h6 className="text-muted text-center text-md-left mb-0 mr-3">{user.email || 'user missing'}</h6>
            <h6 onClick={loguserOut} className="text-center text-md-left noselect pointer mb-0">
              Logout
            </h6>
          </div>
        </div>
      </div>
    </div>
  )
}
