import { getReq } from '../service/API'
import useSWR from 'swr'
import { BASE_URL } from '../constants'
//import { errorStore } from '../store/ErrorStore'

export const useGetImageAnswer = (studyId: string, imageId: string) => {
  const p = BASE_URL + '/api/getImageAnswer?studyId=' + studyId + '&imageId=' + imageId
  const { data, error } = useSWR(p, getReq)

  if (error) {
    console.error(' useGetImageAnswer > error = ', error)
    //setError(error.error)
  }

  return {
    data: !data ? data : data.data,
    isLoading: !error && !data,
    isError: error,
  }
}
