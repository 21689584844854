/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 08/10/2021.
 */
import React from 'react'
import { useGetImageAnswer } from '../hooks/useGetImageAnswer'
import Spinner from './spinner/Spinner'
import { ErrorView } from './error/ErrorView'

type Props = {
  studyId: string
  imageId: string
}

export const AnswerImage = ({ studyId, imageId }: Props) => {
  const { data, isError } = useGetImageAnswer(studyId, imageId)

  if (isError) {
    return <ErrorView isError={isError}></ErrorView>
  }

  const imgSource = `data:image/png;base64,${data?.image}`

  const onImageClick = () => {
    const myWin = window.open('about:blank')

    setTimeout(function () {
      //FireFox seems to require a setTimeout for this to work.
      if (myWin) {
        const el = myWin.document.createElement('img')
        const parent = myWin.document.body.appendChild(el)
        parent.src = imgSource
        parent.style.width = 'auto'
        parent.style.height = '100vh'
        parent.style.margin = 'auto'
      }
    }, 0)
  }

  return (
    <div className="row" style={{}}>
      <div className="col-12 d-flex justify-content-center">
        <div style={{ height: 300 }}>
          {data ? <img className="h-100" src={imgSource} alt="" /> : <Spinner></Spinner>}
        </div>
      </div>

      <div className="col-12 d-flex justify-content-center">
        <div className="mt-2 btn btn-outline-primary" onClick={onImageClick}>
          Open image in new tab
        </div>
      </div>
    </div>
  )
}
