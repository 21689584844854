export const SMART_TRIAL_API = 'https://api.smart-trial.co/v2/external' //study/:studyId/enrollments/:enrollmentId

export const SANDME_API_URL = process.env.REACT_APP_SANDME_API_URL
export const SANDME_BASE_URL = process.env.REACT_APP_SANDME_BASE_URL
export const BASE_URL = process.env.REACT_APP_BASE_URL
export const DEBUG = process.env.REACT_APP_DEBUG === 'true' ? true : false

export const COLOR_DANGER = '#dc3545'
export const COLOR_PLACEHOLDER = '#eaeaea'
export const COLOR_PRIMARY = '#007bff'
export const COLOR_NOT_SELECTED = '#bbbbbb'

export const STATUS_PENDING = 'PENDING'

export const MULTIPLE_CHOICE = 'MULTIPLE_CHOICE'
export const MULTIPLE_RESPONSE = 'MULTIPLE_RESPONSE'
export const TEXT_FREE = 'TEXT_FREE'
export const INFORMATION = 'INFORMATION'
