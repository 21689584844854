/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 24/10/2021.
 */
import React from 'react'
import { Link } from 'react-router-dom'
import { authStore } from '../../store/auth/authStore'

type Props = {
  isError: {
    message: string
  }
  customMessage?: string
}

export const ErrorView = ({ isError, customMessage = '' }: Props) => {
  const logout = authStore((state) => state.logout)

  return (
    <div className="container-fluid">
      <div className="row" style={{}}>
        <div className="col-6 mx-auto">
          <div className="mt-4">
            <h2 className="mb-1 text-center">{isError.message}</h2>
            {customMessage ? (
              <h5 className="text-center">{customMessage}</h5>
            ) : (
              <p className="text-center">This was not expected and could be caused by multiple things</p>
            )}

            <div className="d-flex justify-content-center mt-2">
              <Link to="/" className="btn btn-primary mt-2">
                Back to study overview
              </Link>
              <div onClick={logout} className="btn btn-secondary mt-2 ml-2">
                Logout
              </div>
            </div>
            <div className="text-center mt-2 text-decoration-none">
              <a href="mailto:dennis.christoffersen@studiesandme.com" style={{ color: 'rgba(0,0,0,.5)' }}>
                Contact Technical support
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
