import create, { State } from 'zustand'
import { SANDME_BASE_URL } from '../../constants'
import { AuthType, UserType } from './authTypes'

export const USER_KEY = 'coak-user'

export const tokenKey = 'coak-derm-token'
export const refreshTokenKey = 'coak-derm-refreshtoken'

export interface AuthStoreType extends State {
  token: string | null
  user: UserType
  auth: AuthType
  login: (pass: string, username: string, grantType: string) => Promise<any>
  logout: () => void
}

export type AuthResponse = {
  auth: AuthType
  user: UserType
  msg?: string
  code?: string
}

export const getToken = () => {
  return window.localStorage.getItem(tokenKey)
}

export const getRefreshToken = () => {
  return window.localStorage.getItem(refreshTokenKey)
}

const initUserObj = {
  user: '',
  active: true,
  created: '',
  email: '',
  groupId: '',
  id: '',
  identityProvider: '', //"leoilab"
  isAdmin: true,
  lastLogin: '',
  modified: '',
  profile: {
    firstName: '',
    lastName: '',
  },
  properties: {
    logins: '',
    role: '', //"superadmin",
    siteId: '',
    trialIds: '',
  },
  username: '',
}

export const authStore = create((set: any, get: any) => {
  const userObjStr = window.localStorage.getItem(USER_KEY)
  const parsedUser = userObjStr ? JSON.parse(userObjStr) : initUserObj

  return {
    token: getToken(),
    user: parsedUser,
    auth: {
      accessToken: '',
      expiresIn: '',
      issuedAt: '',
      refreshToken: '',
      tokenType: '',
    },

    logout: () => {
      window.localStorage.setItem(tokenKey, '')
      window.localStorage.setItem(refreshTokenKey, '')
      window.location.replace('/')
    },

    setCredentialsLocalStorage: (authResponse: AuthResponse) => {
      console.log(' authStore > set credentials ')
      if (authResponse.auth.accessToken) {
        window.localStorage.setItem(tokenKey, authResponse.auth.accessToken)
      }
      if (authResponse.auth.refreshToken) {
        window.localStorage.setItem(refreshTokenKey, authResponse.auth.refreshToken)
      }
    },

    setUserLocalStorage: (user: UserType) => {
      console.log(' authStore > set credentials ')
      if (user) {
        const userStrObj = JSON.stringify(user)
        window.localStorage.setItem(USER_KEY, userStrObj)
      }
    },

    login: (pass: string, username: string, grantType: string): Promise<any> => {
      const setCredentialsLocalStorage = get().setCredentialsLocalStorage
      const setUserLocalStorage = get().setUserLocalStorage

      const p = SANDME_BASE_URL + '/v1/users/login'
      return fetch(p, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'X-Client-Id': 'studiesandme',
        },
        body: JSON.stringify({ username: username, password: pass, grantType: grantType }),
      })
        .then((res) => {
          return res.json()
        })
        .then((jsonRes) => {
          if (jsonRes.code) {
            return jsonRes
          }
          // todo - add error handling if response does not match

          console.log(' authStore > jsonRes = ', jsonRes)

          /*if (jsonRes.auth.accessToken) {
						window.localStorage.setItem(tokenKey, jsonRes.auth.accessToken)
					}
					if (jsonRes.auth.refreshToken) {
						window.localStorage.setItem(refreshTokenKey, jsonRes.auth.refreshToken)
					}*/

          setCredentialsLocalStorage(jsonRes)
          setUserLocalStorage(jsonRes.user)

          set((state: AuthStoreType) => ({ user: jsonRes.user, auth: jsonRes.auth, token: getToken() }))

          return jsonRes
        })
    },
  }
})
