import { getReq } from '../service/API'
import useSWR from 'swr'
import { BASE_URL } from '../constants'
//import { errorStore } from '../store/ErrorStore'

export const useGetForm = (studyId: string, formId: string) => {
  const p = BASE_URL + `/api/getForm?studyId=${studyId}&formId=${formId}`

  const cacheKey = formId ? p : null
  const { data, error } = useSWR(cacheKey, getReq)

  /*if (error) {
		setError(error.error)
	}*/

  return {
    data: !data ? data : data.data,
    isLoading: !error && !data,
    isError: error,
  }
}
