/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 01/10/2021.
 */
import React from 'react'
import { Link, Redirect, useParams } from 'react-router-dom'
import { COLOR_PLACEHOLDER } from '../../constants'
import { SpinnerFullHeight } from '../../components/spinner/SpinnerFullHeight'
import { useGetAnswersByDataEvent } from '../../hooks/useGetAnswersByDataEvent'
import { dataStore } from '../../store/dataStore'
import { AnswerImage } from '../../components/AnswerImage'
import { DermEvaluationForm } from './DermEvaluationForm'
import { EventPairs } from '../../Types'
import { ErrorView } from '../../components/error/ErrorView'
import arrowPath from '../../assets/arrow.svg'

export const SubjectPage = () => {
  const { enrollmentId, studyId, subDataEventId } = useParams() as {
    enrollmentId: string
    studyId: string
    subDataEventId: string
  }

  const selectedSubject = dataStore((state) => state.selectedSubject)

  const pendingEventArr: EventPairs[] = selectedSubject?.events.filter((item) => {
    return item.subDataEvent?.dataEvent === subDataEventId
  })

  // TODO - handle multiple pending events
  const pendingEvent: EventPairs | null = pendingEventArr && pendingEventArr.length > 0 ? pendingEventArr[0] : null

  const dataEventRegistrationId = pendingEvent ? pendingEvent.subDataEvent._id : null
  const { data, isError } = useGetAnswersByDataEvent(studyId, enrollmentId, subDataEventId, dataEventRegistrationId)

  const dermCurrentFormId = pendingEvent ? pendingEvent.dermDataEvent.currentForm : null
  const dataRegEvent = pendingEvent ? pendingEvent.dermDataEvent._id : null

  if (isError) {
    return <ErrorView isError={isError}></ErrorView>
  }

  //
  // when the event has been answered - we redirect back to the overview page.
  // the selected subject is set to null in the submit form response
  // this will cause the redirect to be triggered sending the user back to the list page
  //

  if (!pendingEvent || !dermCurrentFormId || !dataRegEvent) {
    return <Redirect to={'/' + studyId}></Redirect>
  }

  //console.log(' SubjectPage > data = ', data)
  /*if (!selectedSubject) {
		return <Redirect to={'/' + studyId}></Redirect>
	}*/

  if (!data) {
    return <SpinnerFullHeight></SpinnerFullHeight>
  }

  console.log(' SubjectPage > data = ', data)
  const imageData = data ? (data.answers[0].images as string[]) : null
  const imageId = imageData ? (imageData[0] as string) : ''

  return (
    <>
      <div className="container-fluid bg-primary">
        <div className="row py-3" style={{}}>
          <Link to={'/' + studyId} className="col-2  d-none d-md-flex text-decoration-none" style={{}}>
            <div
              className="mt-3 alpha-80"
              style={{
                width: 28,
                height: 28,
                transform: 'translate3d(-10px,0,0) rotate(90deg)',
              }}
            >
              <img src={arrowPath} alt="" />
            </div>
            <h4 className="mb-0 pt-1 text-white alpha-80" style={{ marginTop: 12 }}>
              Back
            </h4>
          </Link>

          <div className="col-12 col-md-8">
            <h2 className="font-weight-bold mb-0 text-white text-center">
              {pendingEvent.dermProcessEvent.dataEventName}
            </h2>
            <div className="d-flex justify-content-center text-white">
              <p className="mr-1 text-white-50 ">subjectId:</p>
              <p className="ml-1 alpha-80">{selectedSubject.enrollment.subject.subjectId}</p>
            </div>
          </div>
          <div className="col-2 d-none d-md-block"></div>
        </div>
      </div>

      <>
        <div className="container-fluid " style={{ background: COLOR_PLACEHOLDER }}>
          <div className="row">
            <div className="col-12 py-3">
              <AnswerImage studyId={studyId} imageId={imageId} />
            </div>
          </div>
        </div>

        <div className="container mt-2 ">
          <DermEvaluationForm
            dermFormId={dermCurrentFormId}
            dataRegEvent={dataRegEvent}
            imageId={imageId}
          ></DermEvaluationForm>
        </div>
      </>
    </>
  )
}
