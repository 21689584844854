import { authStore, getRefreshToken, getToken } from '../store/auth/authStore'
import { BASE_URL, DEBUG, SANDME_BASE_URL } from '../constants'

const refreshAttempt: Date[] = []
const MAX_RETRIES = 5

export const getReq = (p: any): any => {
  const token = getToken()

  return fetch(p, {
    method: 'GET',
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  })
    .then((res) => {
      const contentType = res.headers.get('Content-Type')
      const isJson = contentType && contentType.indexOf('application/json') > -1

      if (DEBUG) {
        console.log(' API > res.status = ', res.status, res.statusText)
      }

      if (res.status === 401) {
        return updateAccessToken(p)
      }

      if (!isJson) {
        return res.text()
      }

      return res.json()
    })
    .then((res) => {
      if (res.error || res.result === false) {
        return Promise.reject(res)
      }
      return res
    })

    .catch((err) => {
      return Promise.reject(err)
    })
}

export const post = (p: string, data: any) => {
  const token = getToken()

  return fetch(p, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      if (DEBUG) {
        console.log(' API > res.status = ', res.status, p)
      }

      if (res.status === 401) {
        return updateAccessToken(p, 'POST', data)
      }

      return res.json()
    })

    .catch((err) => {
      return Promise.reject(err)
    })
}

const updateAccessToken = (initialRequest: string, requestType = 'GET', data: any = null) => {
  console.log(' API > updateAccessToken = ')

  refreshAttempt.push(new Date())

  const refreshToken = getRefreshToken()
  const p = SANDME_BASE_URL + '/v1/users/login'

  return fetch(p, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'content-type': 'application/json',
      'X-Client-Id': 'studiesandme',
    },
    body: JSON.stringify({ refreshToken: refreshToken, grantType: 'refreshToken' }),
  })
    .then((res: any) => {
      return res.json()
    })
    .then((json) => {
      //
      // to avoid ddos'ing to our own server
      //
      if (refreshAttempt.length > MAX_RETRIES) {
        //
        // resetting the refreshAttempt should happen automaticlly since the we do a
        // window.location.replace('/') which reloades the window and thereby also the app
        //
        refreshAttempt.slice(MAX_RETRIES)

        authStore.getState().logout()
        return
      }

      authStore.getState().setCredentialsLocalStorage(json)

      if (requestType === 'POST') {
        return post(initialRequest, data)
      } else {
        return getReq(initialRequest)
      }
    })
    .catch((refreshError) => {
      console.log(' API > refreshError = ', refreshError)
      const error = getErrorResponse(refreshError, 401, 'We could not validate your login ')
      return Promise.reject(error)
    })
}

export const getImageUrl = (imageId) => {
  const token = getToken()
  return `${BASE_URL}/v1/images/${imageId}/?authtoken=${token}`
}

function getErrorResponse(err, status: number, msg) {
  return {
    result: false,
    status: status,
    err: err,
    message: msg,
  }
}
